import React from "react"

import useWebiste from "../states/context/websiteContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const { language } = useWebiste()
  return (
    <Layout>
      <SEO title="404: Not found" lang={language} />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
